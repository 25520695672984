<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:parentModelName="parentModelName"
			:parentModel="parentModel"
			:masterDetail="masterDetail"
			:externalFilter="externalFilter"
			:showDeleteBtn="false"
			:showCreateBtn="checkStatusDistress()"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<distressalertssitrep-modals :modelName="modelName"></distressalertssitrep-modals>
	</div>
</template>

<script>
import distressalertssitrepActions from './DistressalertssitrepActions';
import distressalertssitrepModals from './DistressalertssitrepModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'distressalertssitrep-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'distressalertssitrep-modals': distressalertssitrepModals
	},
	data() {
		return {
			modelName: 'distressalertssitrep',
			actions: distressalertssitrepActions.gridactions,
			modelColumnDefs: {}
		};
	},
	methods: {
		checkStatusDistress() {
			if (this.parentModel.statuscode == 'C') {
				return false;
			} else {
				return true;
			}
		}
	}
};
</script>
